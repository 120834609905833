import { useEffect, useState } from 'react';
import {
    ApplicationInsights,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import {
    AppInsightsContext,
    ReactPlugin,
} from '@microsoft/applicationinsights-react-js';

import { useConfig } from './ConfigProvider';

interface IOwnProps {
    children: React.ReactElement;
}

export const AppInsightsProvider: React.FC<IOwnProps> = ({ children }) => {
    const {
        state: {
            appConfig: { applicationInsightsInstrumentationKey },
        },
    } = useConfig();

    const [reactPlugin, setReactPlugin] = useState<ReactPlugin>(
        new ReactPlugin()
    );

    useEffect(() => {
        if (applicationInsightsInstrumentationKey) {
            const reactPlugin = new ReactPlugin();
            setReactPlugin(reactPlugin);

            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: `InstrumentationKey=${applicationInsightsInstrumentationKey};`,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                },
            });

            appInsights.loadAppInsights();

            appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
                env.tags = env.tags || [];
                env.tags['ai.cloud.role'] = 'AccountApp';
            });
        }
    }, [applicationInsightsInstrumentationKey]);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};
